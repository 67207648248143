<template>
	<div>
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="banner">
			<img src="../assets/img/welfarebanner.png">
			<div class="banner-but" @click="applyFromshow = true">立即领取企业降本解决方案</div>
		</div>
		<div class="demand">
			<div class="demand-tit">
				企业的三大需求
			</div>
			<div class="demand-cell flex">
				<div class="demand-cell-ce">
					<div class="demand-cell-ce-box">
						<img style="width: 1.20rem;" src="../assets/img/welfarecell1.png">
						<div style="color: #333;font-size: 0.16rem;font-weight: bold;margin-top: 0.10rem;">多人拼团</div>
						<div style="color: #999999;font-size: 0.16rem;margin-top: 0.10rem;font-size: 0.12rem;">
							基于微信的拼团，开
							团后邀请好友成团</div>
					</div>
				</div>
				<div class="demand-cell-ce">
					<div class="demand-cell-ce-box">
						<img style="width: 1.20rem;" src="../assets/img/welfarecell2.png">
						<div style="color: #333;font-size: 0.16rem;font-weight: bold;margin-top: 0.10rem;">客户礼品</div>
						<div style="color: #999999;font-size: 0.16rem;margin-top: 0.10rem;font-size: 0.12rem;">
							左右为难，客户送礼难
							费用报账难，客情维系难</div>
					</div>
				</div>
				<div class="demand-cell-ce">
					<div class="demand-cell-ce-box">
						<img style="width: 1.20rem;" src="../assets/img/welfarecell3.png">
						<div style="color: #333;font-size: 0.16rem;font-weight: bold;margin-top: 0.10rem;">内部管理</div>
						<div style="color: #999999;font-size: 0.16rem;margin-top: 0.10rem;font-size: 0.12rem;">
							申请审批繁琐，预订渠道分散
							费用管控难，内部成本居高不下</div>
					</div>
				</div>
			</div>
		</div>

		<div class="service">
			<div class="service-top flex">
				<span class="service-top-tit">力瀚提供一站式服务</span>
				<span class="service-top-lab">外部客户关怀、内部员工福利、办公差旅集采解决方案</span>
			</div>
			<div class="service-cent flex">
				<div class="service-cent-cell flex" v-for="(item,index) in cellList" :key="index">
					<div style="width: 1rem;height: 100%;display: flex;flex-direction: column;align-items: center;">
						<img style="width: 0.60rem;margin-top: 0.10rem;" :src="item.img">
						<!-- <el-button size="mini" style="margin-top: 0.10rem;width: 0.8rem;font-size: 0.12rem;text-align: center;">查看详情</el-button> -->
						<!-- <div
							style="margin-top: 0.10rem;width: 0.8rem;font-size: 0.12rem;text-align: center;height: 0.3rem;line-height: 0.3rem; border-radius: 0.05rem;border: 1px solid #ccc;">
							查看详情</div> -->
					</div>
					<div style="flex: 1;height: 100%;margin-left: 0.10rem;text-align: left;">
						<div style="color: #333;font-size: 0.15rem;margin-top: 0.10rem;font-weight: bold;">
							{{item.title}}
						</div>
						<div style="font-size: 0.12rem;margin-top: 0.10rem;color: #666666;padding-right: 0.35rem;line-height: 0.20rem;"
							class="textShow">
							{{item.lable}}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="advantage">
			<div class="advantage-box flex">
				<div style="width: 70%;height: 100%;">
					<div style="width: 100%;height: 100%;margin-left: 1.10rem;justify-content: flex-start;" class="flex">
						<div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_1.png">
								<span class="advantage-box-cell-span">免费平台使用</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div>
						<!-- <div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_2.png">
								<span class="advantage-box-cell-span">税费合规筹划</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div> -->
						<div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_3.png">
								<span class="advantage-box-cell-span">团购优惠价格</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div>
					</div>
					<div style="width: 100%;height: 100%;justify-content: flex-start;" class="flex">
						<div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_8.png">
								<span class="advantage-box-cell-span">提升财务效益</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div>
						<!-- <div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_7.png">
								<span class="advantage-box-cell-span">一站式差旅采购</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div> -->
						<div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_4.png">
								<span class="advantage-box-cell-span">增加市场客户</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div>
					</div>
					<div style="width: 85%;height: 100%;margin: 0 auto;margin-left: 1.1rem;justify-content: flex-start;" class="flex">
						<div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_5.png">
								<span class="advantage-box-cell-span">活跃团队气氛</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div>
						<div class="advantage-box-cell flex">
							<div style="width: 100%;height: 100%;position: absolute;flex-direction: column;"
								class="flex">
								<img class="advantage-box-cell-img" src="../assets/img/advance_6.png">
								<span class="advantage-box-cell-span">专人专项服务</span>
							</div>
							<img src="../assets/img/bg_polygon_1.png">
						</div>
					</div>
				</div>
				<div class="advantage-box-r flex">
					<img style="max-width: 2.80rem;" src="../assets/img/bg_polygon_3.png">
					<img style="position: absolute;width: 90%;height: 90%;" src="../assets/img/bg_polygon_2.png">
					<div class="advantage-box-r-cent flex">
						<span class="advantage-box-r-cent-t">力瀚</span>
						<span class="advantage-box-r-cent-b">优势</span>
					</div>
				</div>
			</div>
		</div>

		<div class="supplier">
			<div class="supplier-tit flex">
				<span class="supplier-tit-tit">我们的合作-品牌供应商</span>
				<span class="supplier-tit-lab">携手行业巨头，整合供应商资源，满足企业员工的福利需求</span>
			</div>
			<div class="supplier-img">
				<img src="../assets/img/supplier.png">
			</div>
		</div>

		<div class="enterprise">
			<div class="supplier-tit flex">
				<span class="supplier-tit-tit">我们的合作-特渠大客户</span>
				<span class="supplier-tit-lab">他们正在使用力瀚，打造幸福企业</span>
			</div>
			<div class="enterprise-img">
				<img src="../assets/img/enterprise-tq.png">
			</div>
		</div>

		<!-- <div class="enterprise">
			<div class="enterprise-tit">他们正在使用力瀚，打造幸福企业</div>
			<div class="enterprise-lab flex">
				<div class="enterprise-lab-cell">
					企业<span class="enterprise-lab-cell-span">省钱</span>
				</div>
				<div class="enterprise-lab-cell">
					老板<span class="enterprise-lab-cell-span">放心</span>
				</div>
				<div class="enterprise-lab-cell">
					员工<span class="enterprise-lab-cell-span">满意</span>
				</div>
				<div class="enterprise-lab-cell">
					HR<span class="enterprise-lab-cell-span">省心</span>
				</div>
			</div>

			<div class="enterprise-img">
				<img src="../assets/img/enterprise-logo.png">
			</div>
		</div> -->

		<div class="programme">
			<div class="programme-tit">现在咨询申清试用 马上获得属于您的福利方案！</div>
			<div class="programme-but flex">
				<!-- <el-button type="primary" class="programme-but-but" @click="customerService = true">点击咨询</el-button> -->
				<div class="programme-but-but flex" @click="customerService = true">点击咨询</div>
				<div class="programme-but-but flex" @click="applyFromshow = true">点击申请</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	import customerService from '@/components/customerService.vue';
	export default {
		data() {
			return {
				applyFromshow: false,
				customerService: false,
				cellList: [{
						img: require('../assets/img/welfare-service1.png'),
						title: '弹性福利',
						lable: '企业福利采购深 企业自由分配积分居 员工自主选择礼品'
					},
					{
						img: require('../assets/img/welfare-service2.png'),
						title: '餐补福利',
						lable: '税费等划，企业省钱 个税方案，员工满意 合法合规'
					},
					{
						img: require('../assets/img/welfare-service3.png'),
						title: '节日生日关怀',
						lable: '员工生日关怀礼品 春节、元宵、女神节 中秋节等节日关怀'
					},
					{
						img: require('../assets/img/welfare-service4.png'),
						title: '员工健康体检',
						lable: '800多家专业体检机构 100多种体检套餐 全国地区通用'
					},
					{
						img: require('../assets/img/welfare-service5.png'),
						title: '福利商城',
						lable: '打造员工积分商城 无缝接入企业系统 个性化定制功能模块'
					},
					{
						img: require('../assets/img/welfare-service6.png'),
						title: '员工激励',
						lable: '团队积分激励 员工点赞互动 领导积分打赏'
					},
					{
						img: require('../assets/img/welfare-service7.png'),
						title: '企业办公采购',
						lable: '企业预算合理化 采购简易化 集中预算，统一审批'
					},
					// {
					// 	img: require('../assets/img/welfare-service8.png'),
					// 	title: '交通差旅',
					// 	lable: '为企业提供滴滴打车 火车票预订、企业机票预 订、酒店预订等服务'
					// },
					{
						img: require('../assets/img/welfare-service9.png'),
						title: '企业客户关怀',
						lable: '礼品采购、礼品卡 客户礼品方案 解决送礼难、报账难等问题'
					}
				]
			}
		},
		components: {
			// Canvas，
			applyFrom,
			customerService
		},

		methods: {
			close() {
				this.applyFromshow = false;
			},
			closePopup() {
				this.customerService = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	img {
		width: 100%;
	}

	@media screen and (max-width: 750px) {
		.demand-cell {
			width: 80% !important;
		}

		.advantage-box {
			width: 80% !important;
		}

		.supplier-img {
			width: 90% !important;
		}

		.enterprise-img {
			width: 90% !important;
		}

		.advantage-box-r-cent-t {
			font-size: 0.3rem !important;
		}

		.banner-but {
			width: 2rem !important;
			height: 2rem !important;
			font-size: 0.12rem !important;
			bottom: -1.3rem !important;
			left: 1.6rem !important;
			line-height: 0.32rem !important;
		}

		.advantage-box-r-cent-b {
			font-size: 0.4rem !important;
		}

		.advantage-box-cell-img {
			width: 0.5rem !important;
		}

		.service-cent {
			width: 90% !important;
			
			.service-cent-cell{
				margin-right: 0.2rem !important;
			}
		}
	}

	.textShow {
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		word-break: break-all;
	}

	.banner {
		width: 100%;
		position: relative;
		z-index: 101;

		.banner-but {
			background-image: url(../assets/img/welfarebanner-but.png);
			background-repeat: no-repeat;
			background-size: 100%;
			width: 4rem;
			height: 0.96rem;
			font-size: 0.23rem;
			line-height: 0.6rem;
			font-family: Noto Sans S Chinese;
			font-weight: 400;
			color: #F6F6F6;
			position: absolute;
			bottom: 0.8rem;
			left: 4.2rem;
		}
	}

	.demand {
		width: 100%;
		padding-bottom: 0.50rem;
		background-color: #F7F7F7;

		.demand-tit {
			width: 2.45rem;
			height: 0.96rem;
			background: rgba(145, 195, 248, 0.37);
			border: 2px solid #2b8ff838;
			border-image: linear-gradient(0deg, #2B8FF8) 2 2;
			box-shadow: 0px 2px 9px 1px rgba(168, 168, 168, 0.2);
			// opacity: 0.5;
			border-radius: 0px 0px 0.50rem 0.50rem;
			margin: 0 auto;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 0.96rem;
			color: #333333;
		}

		.demand-cell {
			width: 60%;
			height: 1.80rem;
			background-color: #fff;
			box-shadow: 0px 2px 9px 1px rgba(168, 168, 168, 0.2);
			margin: 0 auto;
			margin-top: 1.20rem;
			margin-bottom: 0.50rem;

			.demand-cell-ce {
				flex: 1;
				height: 100%;
				position: relative;

				.demand-cell-ce-box {
					position: absolute;
					width: 70%;
					margin: 0 auto;
					left: 0;
					right: 0;
					top: -0.60rem;
				}
			}
		}
	}

	.service {
		width: 100%;

		.service-top {
			width: 100%;
			height: 1.00rem;
			line-height: 0.40rem;
			padding: 0.40rem 0;
			flex-direction: column;

			.service-top-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.service-top-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.service-cent {
			// width: 50%;
			width: 10.50rem;
			margin: 0 auto;
			flex-wrap: wrap;
			justify-content: unset;
			padding-top: 0.20rem;
			padding-bottom: 0.40rem;

			.service-cent-cell {
				// width: 2.46rem;
				width: 30%;
				// height: 122px;
				padding: 0.10rem 0;
				margin-right: 0.32rem;
				margin-bottom: 0.20rem;
				background: #FFFFFF;
				box-shadow: 0px 2px 9px 1px rgba(168, 168, 168, 0.2);
				border-radius: 10px;
			}
		}
	}

	.advantage {
		width: 100%;
		background-color: #F7F7F7;

		.advantage-box {
			width: 50%;
			margin: 0 auto;
			padding: 0.60rem 0;
			justify-content: initial;

			.advantage-box-cell {
				width: 1.40rem;
				height: 1.60rem;
				flex-direction: column;
				line-height: 0.30rem;
				// background-image: url(../assets/img/bg_polygon_1.png);
				position: relative;
				margin-right: 0.45rem;

				.advantage-box-cell-img {
					width: 0.68rem;
				}

				.advantage-box-cell-span {
					font-size: 0.12rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
				}
			}

			.advantage-box-r {
				// width: 280px;
				// height: 320px;
				margin-top: 1.00rem;
				margin-left: 0.25rem;
				position: relative;
				// background-image: url(../assets/img/bg_polygon_3.png);

				.advantage-box-r-cent {
					width: 100%;
					height: 100%;
					flex-direction: column;
					position: absolute;
					// background-image: url(../assets/img/bg_polygon_2.png);

					.advantage-box-r-cent-t {
						font-size: 0.43rem;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #333333;
					}

					.advantage-box-r-cent-b {
						font-size: 0.72rem;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #FA7C4F;
					}
				}
			}
		}
	}

	.supplier {
		width: 100%;
		// height: 700px;
		padding-top: 0.60rem;
		padding-bottom: 1.00rem;

		.supplier-tit {
			width: 100%;
			height: 1.50rem;
			line-height: 0.40rem;
			flex-direction: column;

			.supplier-tit-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.supplier-tit-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.supplier-img {
			width: 14.38rem;
			margin: 0 auto;
		}
	}

	.enterprise {
		width: 100%;
		background-color: #F7F7F7;
		padding: 0.60rem 0;

		.supplier-tit {
			width: 100%;
			height: 1.50rem;
			line-height: 0.40rem;
			flex-direction: column;

			.supplier-tit-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.supplier-tit-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.enterprise-tit {
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
		}

		.enterprise-lab {
			width: 50%;
			margin: 0 auto;
			padding: 0.40rem 0;

			.enterprise-lab-cell {
				flex: 1;
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #333333;

				.enterprise-lab-cell-span {
					font-size: 0.50rem;
					color: #D00000;
				}
			}
		}

		.enterprise-img {
			// width: 45%;
			width: 14.36rem;
			margin: 0 auto;
			margin-top: 0.20rem;
		}
	}

	.programme {
		width: 100%;
		height: 2.00rem;
		background-image: url(../assets/img/dg-bg.png);
		background-repeat: no-repeat;

		.programme-tit {
			width: 100%;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			padding: 0.40rem 0;
		}

		.programme-but {
			// width: 100%;
			margin: 0 auto;

			.programme-but-but {
				width: 1.50rem;
				height: 0.45rem;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-right: 0.60rem;
				border-radius: 5px;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
			}
		}
	}
</style>
